.heading {
  height: 11vh;
  display: flex;
  width: 100%;
  align-items: flex-end;
  padding: 21px;
  border-bottom: 1px solid #70707041;
}

.options-title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #000;
}
