.table-header {
  display: inline-flex;
  width: 100%;
  position: sticky;
  top: 0;
  font-size: 2vh;
  font-weight: bold;
  height: 10vh;
  padding: 50px 74px 29px 74px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.41);
}

.table-header-mobile {
  white-space: nowrap;
  position: sticky;
  top: 0;
  width: 2070px;
  font-size: 2vh;
  font-weight: bold;
  height: 10vh;
  padding: 50px 35px 0px 35px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.41);
}
.table-header2 {
  position: sticky;
  display: inline-flex;
  width: 100%;
  background-color: white;
}

.animated-icon {
  animation: rotate 0.2s forwards linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.table-row {
  display: flex;
  width: 100%;
  font-family: "Roboto";
  font-style: italic;
  font-size: 2vh;
  color: rgb(112, 112, 112);
  text-overflow: ellipsis;
  padding: 2vh;
  border-bottom: 1px solid rgba(112, 112, 112, 0.19);
  cursor: pointer;
}

.table-row-mobile {
  white-space: nowrap;
  font-family: "Roboto";
  font-style: italic;
  width: 2070px;
  font-size: 2vh;
  color: rgb(112, 112, 112);
  padding: 2vh 35px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.19);
  cursor: pointer;
}

.table-row:hover {
  background-color: rgba(112, 112, 112, 0.09);
}

.table-row-mobile:hover {
  background-color: rgba(112, 112, 112, 0.09);
}

.add_letter {
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 10;
  height: 7.5vh;
  width: 7.5vh;
  border-radius: 50%;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
}

.add_icon {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3vh;
  width: 3vh;
}
