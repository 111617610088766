
.signout-button {
  background: white;
  width: 7vw;
  height: 5vh;
  border: none;
  border-radius: 7px;
  font-size: 17px;
  font-weight: normal;
}

.shadow-custom{
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 101;
}