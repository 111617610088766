.item-enter {
  opacity: 0;
  transform: scale(0.9);
}
.item-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.submit {
  margin: 24px 20%;
  margin-top: 0;
  width: 60%;
  height: 7.6vh;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #fff;
  padding: 0;
  border: none;
  background: none;
  border-radius: 7px;
}
