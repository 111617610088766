.daily-insights-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.daily-insights-header {
  font-size: 13px;
  font-weight: bold;
  padding: 17px;
  border-radius: 10px 10px 0 0;
  background-color: #fff9f2;
  border-bottom: 1px solid rgba(112, 112, 112, 0.19);
}

.daily-insights-action {
  padding: 14px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 0 10px;
  border-radius: 0 0 10px 10px;
  background-color: #fff9f2;
  border-top: 1px solid rgba(112, 112, 112, 0.19);
}

.daily-insights-body {
  padding: 40px 30px;
  height: 310px;
  background-color: #fff;
}

.daily-insights-button {
  padding: 3px 15px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
}

.daily-insights-button.cancel {
  color: #707070;
  background-color: transparent;
  border: 1px solid #707070;
}

.daily-insights-button.save {
    color: #fff;
}
