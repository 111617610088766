.daily-insights-grid {
    display: grid;
    grid-gap: 59px;
    grid-template-columns: repeat(auto-fill, 313px);
    width: 100%;
    padding: 60px;
}


.input-style {
    font-size: 1.9vh;
    width: 100%;
    border: 1px solid #dadada;
    border-radius: 5px;
    padding: 10px;
}