#quill-container {
  height: auto;
  min-height: 100%;
}
#quill-container .ql-editor {
  font-size: 18px;
  overflow-y: visible;
}

.ql-tooltip {
  z-index: 100;
}

.ql-container {
  font-size: 1.9vh;
}
.ql-editor {
  border-radius: 4px !important;
  border-style: solid !important;
  border: 1px solid transparent !important;
  color: black !important;
  width: 100% !important;
}

.field-container {
  border-radius: 4px !important;
  border-style: solid !important;
  padding: 0px !important;
  border: 1px solid #dadada !important;
  color: black !important;
  width: 100% !important;
}

.directionality {
  direction: rtl;
  align-items: right;
}

#custom-button {
  font-weight: bold;
  color: #cccccc;
}

.ql-container > .ql-editor.ql-blank::before {
  font-style: normal !important;
}
