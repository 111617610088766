.user-image{
    height: 9.3vh;
    width: 9.3vh;
    border-radius: 50%;
    margin-right: 20px;
}

.container {
    height: auto;
    width: 100%;
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding: 4.4vh 2.5vw;
    font-size: 3.3vh;
    color: rgba(0, 0, 0, 0.66);
    font-weight: bold;
}

.dropdown-content-data {
    margin-top: 3vh !important;
    height: 5.4vh !important;
    width: 100% !important;
    border-radius: 7px !important;
    border: 1px solid #DADADA !important;
    font-size: 1.9vh;
    font-weight: 500;
    padding: 0px 1vw;
    color: black !important;
}

.dropdown-content-data::placeholder {
    color: #9d9d9d !important
}