.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 450px);
    grid-gap: 3.9vh;
    padding: 56px 99px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }