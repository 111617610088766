.visibility-button {
    border: 1px solid #DEDEDE;
    border-radius: 10px;
    font-size: 12px;
    text-align: start;
    padding: 10px;
    font-weight: lighter;
    margin: 10px 0px;
    background-color: #F7F7F7;
}

.visibility-button-special {
    width: 100%;
}


.header-text {
    font-size: 14px;
    font-family: "Lato";
    font-weight: bold;
    color: black;
}


.header-text-special {
    margin: auto;
    padding: 4px;
    text-align: center;
    font-size: 14px;
    font-family: "Lato";
    font-weight: bold;
    color: black;
}


.footer-style {
    display: flex;
    justify-content: center;
    width: 100%;
}

.footer-style-special {
    margin-top: 16px;
}

.footer-button {
    border: 1px solid transparent;
    height: 4.2vh;
    width: 100px;
    border-radius: 3px;
    font-size: 1.4vh;
    font-weight: bold;
    font-family: "Lato";
    margin: 0px 10px;
}

.footer-button.mobile {
    width: 40vw;
}

.field-modal {
    width: 100%;
    color: #000;
    font-size: 1.5rem;
    border: none;
    outline: none;
    resize:none;
    overflow:hidden;
    /* margin: 0 20px 1.5em 0 !important; */
    padding: 2px 20px 20px 0;
  }
  
