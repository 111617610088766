.wrapper {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.textfield {
  margin-top: 4vh !important;
  height: 58px !important;
  width: 315px !important;
  border-radius: 7px !important;
  border: 1px solid #dadada !important;
  font-size: 1.9vh;
  font-weight: 500;
  padding: 0px 20px;
  color: black !important;
}

.textfield::placeholder {
  color: #9d9d9d !important;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 315px !important;
  margin-top: 55px;
}

.image-back {
  position: absolute;
  top: -70px;
  left: 180px;
  width: 151px;
  height: 151px;
  background: #fff;
  border-radius: 150px;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
}

.image {
  border-radius: 150px;
  width: 131px;
  height: 131px;
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}
