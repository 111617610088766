.container-bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.11);
}

.main-container {
  height: 484px;
  width: 676px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
}

.header-section {
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #70707041;
  padding: 15px 24px;
}

.body-section {
  width: 100%;
  flex: 1;
  padding: 24px;
}

.body-section select {
  padding: 0 12px;
  overflow: hidden;
  flex: 1;
  background-position: right 10px center;
}

.body-section-content {
  display: grid;
  overflow: scroll;
  height: 325px;
  grid-template-columns: 181px 181px 181px;
  grid-gap: 24px;
}

.body-section-content-item {
  border-radius: 10px;
  height: 181px;
  background-color: white;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  width: 181px;
}


.footer-section {
  width: 100%;
  display: flex;
  border-top: 1px solid #70707041;
  justify-content: flex-end;
  padding: 15px 24px;
}
