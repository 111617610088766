#header {
  display: inline-flex;
  width: 100%;
  position: sticky;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  height: 100px;
  padding: 4vh 60px 0px 60px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.41);
}

.header-text {
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  padding: 2px 8px;
  text-align: left;
  color: #000;
}

.header-text:hover {
  background-color: #9793934b;
  border-radius: 4px;
  cursor: pointer;
}

.tile {
  display: inline-flex;
  width: calc(100% - 100px);
  height: 70px;
  align-items: center;
  margin: 0 50px;
  padding: 0 15px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.41);
}
.ghost {
  background: rgb(197, 233, 245) !important;
}

.tile:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.tile-text {
  font-weight: normal;
  font-size: 18px;
  padding-left: 10px;
  text-align: center;
  color: #707070;
}

.options-title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #000;
}

.popup-background {
  width: 100vw;
  height: 100vh;
  z-index: 1030;
  top: 0;
  left: 0;
  position: fixed;
  background: transparent;
  backdrop-filter: blur(5px);
  --webkit-backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.11);
}

.popup-container {
  position: relative;
  width: 670px;
  height: 480px;
  z-index: 1031;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
}

.popup-header {
  display: flex;
  position: absolute;
  top: 0;
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 0 30px;
  border-bottom: 1px solid #70707030;
}
.popup-footer {
  display: flex;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 10px 10px;
  justify-content: end;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 0 30px;
  border-top: 1px solid #70707030;
}

.popup-tile {
  display: inline-flex;
  width: calc(100% - 60px);
  height: 50px;
  align-items: center;
  margin: 0 30px;
  padding: 0 15px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.41);
  cursor: pointer;
}

.popup-tile.tag{
  cursor: default;
}

.popup-tile:hover {
  background-color: #f5f5f5;
}

.popup-tile-text {
  font-family: "Roboto";
  font-weight: normal;
  flex-grow: 1;
  font-size: 13px;
  padding-left: 10px;
  text-align: left;
  color: #707070;
}

.search {
  background-color: white;
  background-image: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698956-icon-111-search-512.png");
  background-position: right center;
  background-size: 20px;
  background-repeat: no-repeat;
}
.search::placeholder {
  font-size: 13px;
  color: #b7b7b7;
  line-height: 1;
}

.tags-input {
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 21px;
  width: 100%;
  padding: 8px;
  padding-top: 0;
  border: 1px solid rgba(112, 112, 112, 0.16);
  overflow: scroll;
  border-radius: 6px;
}

.tags-input input {
  margin-top: 8px;
  flex: 1;
  border: none;
  font-size: 15px;
  padding: 4px 0 0 0;
  color: #000;
  font-size: 13px;
}
.tags-input input:focus {
  outline: transparent;
}

.chip {
  border-radius: 9999px;
  font-family: Roboto;
  display: flex;
  font-weight: bold;
  font-size: 10px;
  text-align: left;
  align-items: center;
  padding: 6px;
  margin: 8px 8px 0 0;
  font-size: 10px;
  color: white;
  cursor: pointer;
}

.react-contexify {
  min-width: 100px !important;
}
