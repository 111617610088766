.image-uploader-container {
    height: 93px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 21px;
    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.16);
}

.image-uploader-container.newimage {
    justify-content: flex-start;
    gap: 0 10px;
}

.image-uploader-header {
    font-size: 19px;
    font-weight: bold;
    color: #5a5a5a;
}

.image-uploader-button {
    font-size: 16px;
    font-weight: bold;
    padding: 15px 30px;
}

.image-uploader-button.browse {
    border-radius: 3px;
    color: white;
}