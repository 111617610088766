.card-container {
    background-color: #fff;
    height: 313px;
    width: 313px;
    border-radius:11px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    transition: all 0.3s ease;
}

.card-image {
    height: 313px;
    width: 313px;
    position: absolute;
    border-radius: 11px;
    z-index: 1;
    object-fit: cover;
}

.card-overlay {
    height: 313px;
    width: 313px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 20px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.32);
    z-index: 2;
    border-radius: 11px;
}

.icon-button {
    width: 45px;
    height: 45px;
    z-index: 3;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.icon-style {
    width: 18px;
    height: 18px;
    margin: 0 !important;
}