.dropbtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 9.5vw;
    color: white;
    padding: 1.6vh;
    font-size: 1.9vh;
    font-weight: 500;
    border: none;
    cursor: pointer;
    border-radius: 7px;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    margin-top: 4vh;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    width: 9.5vw;
    border-radius: 0px 0px 7px 7px;
    z-index: 1;
  }
  
  .dropdown-content div {
    color: white;
    padding: 1.6vh;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  
  .dropdown-content div:hover {
  }

  .dropdown:hover .dropbtn {
    border-radius: 7px 7px 0px 0px;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  