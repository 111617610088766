.glow {
  display: flex;
  border-radius: 17px;
  font-family: "Lato";
  width: 25%;
  margin-left: 2vw;
  margin-bottom: 3vh;
  height: 67vh;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  flex-direction: column;
  overflow-x: hidden;
  padding: 2vh;
  overflow-y: auto;
  text-align: center;
  align-items: center;
  align-self: center;
}

.glow.mobile {
  border-radius: 17px;
  font-family: "Lato";
  border-radius: 10px;
  flex-direction: unset;
  background: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  /* padding: 2vh; */
  text-align: center;
  align-items: center;
  align-self: center;
  overflow-x: auto;
  overflow-y: hidden;
  height: 142px;
  width: 100%;
  margin: 0;
  margin-bottom: 30px;
}

.browse-file {
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 10px 20px;
  border-radius: 3px;
  font-family: "Lato";
  align-self: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.browse-file.mobile {
  margin-top: 5px;
  padding: 5px 10px;
}
