*{
  margin: 0;
  padding: 0;
}
.body{
  height: 100%;
  width: 100%;
}
.signin-button {
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: #126664;
  color: #fff;
  border-radius: 1.4vh;
  font-size: 1.1vw;
  font-weight: bold;
  margin: 5vh 0vh;
  height: 6vh;
  width: 9vw;
}
.mob-signin-button {
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: #126664;
  color: #fff;
  border-radius: 1.4vh;
  font-size: 2vh;
  font-weight: bold;
  margin: 2vh 0vh;
  height: 6vh;
  width: 50vw;
}

.login-field {
  height: 6vh;
  width: 15vw;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
  border: 1px solid rgba(112, 112, 112, 0.44);
  margin: 2vh 0px;
  font-size: 0.9vw;
  color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.login-field:focus {
  outline: none;
}
.mobile-login-field {
  height: 50px;
  width: 80vw;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
  border: 1px solid rgba(112, 112, 112, 0.44);
  margin: 2vh 0px;
  font-size: 2.4vh;
  color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.mobile-login-field:focus {
  outline: none;
}
