#quill-container {
  height: auto;
  min-height: 100%;
}
#quill-container .ql-editor {
  font-size: 18px;
  overflow-y: visible;
}

.ql-container {
  font-size: 1.9vh;
}
.ql-editor {
  padding: 6px 12px;
}
.ql-editor p {
  padding-bottom: 15px;
}

.directionality {
  direction: rtl;
  align-items: right;
}
/* Specify our own scrolling container */
#scrolling-container {
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
}
#custom-button .ql-active {
  font-weight: bold;
  color: white;
}
#custom-button {
  font-weight: bold;
  color: #cccccc;
}

legend {
  font-size: 2.3vh !important;
  color: rgba(0, 0, 0, 0.5) !important;
  text-align: left !important;
  width: auto !important;
  padding: 0 10px !important;
  border-bottom: none !important;
  margin: 0px !important;
}

fieldset {
  border: 1px groove rgba(112, 112, 112, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 0px 0px 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000 !important;
  box-shadow: 0px 0px 0px 0px #000 !important;
}

.field {
  border: 1px solid rgba(112, 112, 112, 0.16) !important;
  border-radius: 4px !important;
  margin: 0 20px 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000 !important;
  box-shadow: 0px 0px 0px 0px #000 !important;
}

svg {
  margin-right: 7px !important;
}
legend {
  font-size: 2.3vh !important;
  color: rgba(0, 0, 0, 0.5) !important;
  text-align: left !important;
  width: auto !important;
  padding: 0 10px !important;
  border-bottom: none !important;
  margin: 0px !important;
}

fieldset {
  border: 1px groove rgba(112, 112, 112, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 0px 0px 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000 !important;
  box-shadow: 0px 0px 0px 0px #000 !important;
}

.field {
  border: 1px solid rgba(112, 112, 112, 0.16) !important;
  border-radius: 4px !important;
  margin: 0 20px 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000 !important;
  box-shadow: 0px 0px 0px 0px #000 !important;
}
