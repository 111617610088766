.sidebar {
  height: 92%;
  width: 250px;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 8vh;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 35px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
}

.collapsed {
  left: -250px;
}

.outline-button {
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
  width: calc(100% - 50px);
  position: relative;
  padding: 12px 15px;
  border: 1px solid rgba(112, 112, 112, 0.19);
  border-radius: 3px;
  display: inline-block;
  align-items: center;
  align-content: center;
  color: #a0a0a0;
  transition: 0.3s;
}

.outline-button:hover {
  background-color: rgba(112, 112, 112, 0.1);
}

.footer {
  align-self: center;
  text-align: center;
  width: 100%;
  font-weight: normal;
  font-size: 16px;
}
