.editor {
  padding: 30px 50px;
}
.editor-mobile {
  padding: 30px 25px;
  width: 100vw;
}

input {
  border: 1px solid rgba(112, 112, 112, 0.16);
  color: rgba(112, 112, 112, 0.46);
  font-size: 1.9vh;
  border-radius: 4px;
}

input:focus {
  outline: none;
}
input.day {
  font-size: 1.9vh;
  width: 7.77vh;
  height: 5.2vh;
  text-align: center;
  color: black;
  margin-right: 20px;
}
input.year {
  font-size: 1.9vh;
  width: 14vh;
  height: 5.2vh;
  text-align: center;
  color: black;
  margin-right: 20px;
}
input.hash {
  width: 7.77vh;
  height: 5.2vh;
  text-align: left;
  font-size: 1.9vh;
  padding-left: 10px;
  color: black;
  margin-right: 20px;
}
input.day-mobile {
  font-size: 1.9vh;
  width: 14%;
  height: 5.2vh;
  text-align: center;
  color: black;
}
input.year-mobile {
  font-size: 1.9vh;
  width: 28%;
  height: 5.2vh;
  text-align: center;
  color: black;
}
input.hash-mobile {
  width: 14%;
  height: 5.2vh;
  text-align: left;
  font-size: 1.9vh;
  padding-left: 10px;
  color: black;
}

select {
  border: 1px solid rgba(112, 112, 112, 0.16);
  color: rgba(112, 112, 112, 0.46);
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%23E8E8E8' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: 11.2vh 0.7vh;
  font-size: 1.9vh;
  padding: 0 0 0 0;
  border-radius: 4px;
  margin: 0 32px 0 0;
}
select:focus {
  outline: none;
}

select.month {
  font-size: 1.9vh;
  width: 14.7vh;
  height: 5.2vh;
  text-align: center;
  color: black;
  margin-right: 20px;
  margin-left: 20px;
  text-align: left;
  padding-left: 10px !important;
  padding-right: 20px !important;
  margin-bottom: 6.4vh;
}
select.article-status {
  font-size: 1.9vh;
  width: 14.7vh;
  height: 5.2vh;
  text-align: center;
  color: black;
  text-align: left;
  padding-left: 10px !important;
  padding-right: 20px !important;
}

select.month-mobile {
  font-size: 1.9vh;
  width: 28%;
  height: 5.2vh;
  text-align: center;
  color: black;
  text-align: right;
  padding-left: 10px !important;
  margin-bottom: 6.4vh;
}

.preview {
  color: #898989;
  height: 5.2vh;
  border: 1px solid rgba(112, 112, 112, 0.16);
  font-size: 1.9vh;
  border-radius: 4px;
  gap: 0 10px;
  vertical-align: middle;
  padding-left: 10px;
  display: inline-flex;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.space-between.mobile {
  display: flex;
  height: 5.2vh;
  margin: 0;
  margin-bottom: 31px;
  justify-content: space-between;
}

.submit {
  width: 100%;
  height: 7.6vh;
  font-weight: bold;
  font-size: 2.4vh;
  text-align: center;
  color: #fff;
  padding: 0;
  border: none;
  background: none;
  border-radius: 3px;
}
