.user-card {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #dadada;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 15px;
  padding: 1.3vw 1.5vw;
  cursor: pointer;
}

.title {
  font-size: 2.3vh;
  opacity: 0.66;
  color: #000;
}
.subtitle {
  font-size: 1.9vh;
  opacity: 0.5;
  color: #000;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transform: scale(0.6);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.react-contexify{
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 5%) !important;
}
