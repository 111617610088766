.preview-card {
  display: inline-block;
  height: 171px;

  width: 100%;
  background-color: #f7f7f7;
  margin-bottom: 18px;
  border-radius: 6px;
  border: 1px solid #dedede;
}

.preview-card.mobile {
  margin-bottom: 0;
  width: 196px;
  height: 117px;
  margin-right: 20px;
}

.preview-image {
  height: 127px;
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dedede;
}

.preview-image.mobile{
  height: 86px;
}

.file-card {
  display: inline-flex;
  width: 100%;
  align-items: center;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  height: 44px;
  font-size: 2vh;
}

.file-card.mobile{
  height: 31px;
}

.file-name {
  max-width: 10ch;
  text-decoration: none;
  text-decoration-color: transparent;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.setting-icon {
  position: absolute;
  top: 10px;
  right: 8px;
}

.eye-icon {
  position: absolute;
  top: 35px;
  right: 8px;
}


a:hover{
  text-decoration: none !important;
}